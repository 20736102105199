<template>
  <b-card>
    <b-card-header />

    <b-col
      offset-md="4"
      md="3"
    >
      <b-dropdown
        variant="outline-primary"
        block
        menu-class="w-100"
      >
        <template #button-content>
          <feather-icon
            icon="CommandIcon"
            size="16"
            class="align-middle"
          />
          {{ $t('Components.ListHeader.Actions') }}
        </template>
        <b-dropdown-item @click="getRolesMatrix()">
          <feather-icon icon="DownloadIcon" size="16" />
          <span class="align-middle ml-50">{{ $t('AppSecurity.actions.downloadRolesMatrix') }}</span>
        </b-dropdown-item>
        <b-dropdown-item @click="getUsersMatrix()">
          <feather-icon icon="DownloadIcon" size="16" />
          <span class="align-middle ml-50">{{ $t('AppSecurity.actions.downloadUsersMatrix') }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-col>
  </b-card></template>
<script>

import { defineComponent } from '@vue/composition-api'
import {
  BCard, BCardHeader, BRow, BCol, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import securityService from '@/services/security.service'
import { saveAs } from 'file-saver'

export default defineComponent({
  components: {
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
  },
  setup() {
    const {
      fetchPermissionsRolesExportExcel,
      fetchPermissionsUsersExportExcel,
    } = securityService()

    const getRolesMatrix = () => {
      fetchPermissionsRolesExportExcel(null, data => {
        const file = new File([data], 'MatrizRoles.xlsx')
        saveAs(file, 'MatrizPermisosRoles.xlsx')
      })
    }

    const getUsersMatrix = () => {
      fetchPermissionsUsersExportExcel(null, data => {
        const file = new File([data], 'MatrizUsuarios.xlsx')
        saveAs(file, 'MatrizPermisosUsuarios.xlsx')
      })
    }
    return {
      fetchPermissionsRolesExportExcel,
      fetchPermissionsUsersExportExcel,
      getUsersMatrix,
      getRolesMatrix,
    }
  },
})
</script>
<style lang="scss">

</style>
