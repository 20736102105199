import axios from '@axios'

export default function securityService() {
  // ===================================================================|
  //  Permissions Matrix --------------------------------------------------------------------------------------------|
  // ===================================================================|
  const fetchPermissionsRolesExportExcel = (data, callback) => new Promise(reject => {
    axios
      .get('/Seguridad/MatrizPermisosRoles/ExportarExcel', { params: data, responseType: 'blob' })
      .then(response => {
        callback(response.data)
      })
      .catch(error => reject(error))
  })

  const fetchPermissionsUsersExportExcel = (data, callback) => new Promise(reject => {
    axios
      .get('/Seguridad/MatrizPermisosUsuarios/ExportarExcel', { params: data, responseType: 'blob' })
      .then(response => {
        callback(response.data)
      })
      .catch(error => reject(error))
  })

  return {
    // Permissions Matrix
    fetchPermissionsRolesExportExcel,
    fetchPermissionsUsersExportExcel,
  }
}
